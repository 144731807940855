// DOWNLOAD GUIDE
import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';

import { motion, useScroll, useSpring } from 'framer-motion';
import { Helmet } from 'react-helmet';
import PageSEO from '../components/PageSEO';
import CaseStudiesSection from '../components/sections/CaseStudiesSection';
import CtaSection from '../components/sections/CtaSection';
import JourneySection, { JourneySectionProps } from '../components/sections/JourneySection';
import MethodSection, { MethodSectionProps } from '../components/sections/MethodSection';
import NoteSection, { NoteSectionProps } from '../components/sections/NoteSection';
import TestimonialsSection, {
  TestimonialsSectionProps,
} from '../components/sections/TestimonialsSection';
import WhyUsSection, { WhyUsSectionProps } from '../components/sections/WhyUsSection';
import Button from '../components/ui/Button';
import HeroBigVideo, { HeroBigVideoProps } from '../components/ui/HeroBigVideo';
import SmartLink from '../components/ui/SmartLink';
import { AUTHOR_NAME, FILE_QUERY_PARAM, NOAUTODOWNLOAD_QUERY_PARAM } from '../constants';
import { useNotifications } from '../contexts/NotificationsContext';
import '../fragments';
import { CaseStudy, LocalizedSEOLandingPage, SanityImageType } from '../fragments';
import logoImageUrl from '../images/logo.svg';
import { getSerializers } from '../serializers';
import { RawPortableText } from '../types';
import { useActivateGoogleOptimize } from '../utils/hooks';
import { replaceNewLinesWithBr } from '../utils/sanity';
import { clsx, removeParamsFromQueryParams, slugify, withDataLayer } from '../utils/utils';
import * as styles from './GuideWorkshopPage.module.scss';

const CTA_QUERY_PARAM = 'cta';

export const query = graphql`
  query GetGuideWorkshopPageById($id: String!) {
    sanityGuideWorkshop(_id: { eq: $id }) {
      slug {
        current
      }
      topBannerText
      hero {
        title
        videoUrl
        videoTitle
        videoSubtitle
        showClientBrands
      }
      noteSection {
        title
        subtitle
        rows {
          image {
            ...SanityImage
          }
          title
          _rawText(resolveReferences: { maxDepth: 4 })
        }
      }
      whyUsSection {
        title
        textRows {
          title
          _rawText(resolveReferences: { maxDepth: 4 })
        }
      }
      methodSection {
        title
        introText
        textColumns {
          title
          _rawText(resolveReferences: { maxDepth: 4 })
        }
        equation {
          strategyEquationTextIntro
          strategyEquationText1
          strategyEquationText2
          strategyEquationText3
        }
      }
      journeySection {
        title
        textBlocks {
          title
          _rawText(resolveReferences: { maxDepth: 4 })
        }
      }
      caseStudies {
        ...CaseStudy
      }
      testimonialsSection {
        testimonialsToUse
        referencesToUse
        testimonialReferences {
          quote
          author
          position
          image {
            ...SanityImage
          }
        }
        testimonials {
          quote
          author
          position
          image {
            ...SanityImage
          }
        }
      }
      ctaSection {
        formType
        title
        text
        url
        typeformId
      }
      seo {
        ...SEOLandingPage
      }
    }
    allSanityClientBrand(sort: { fields: orderRank }) {
      nodes {
        title
        whiteLogo {
          ...SanityImage
        }
        darkLogo {
          ...SanityImage
        }
      }
    }
    sanityGetFileSettings {
      downloadableFiles {
        fileParameterSlug {
          current
        }
        fileUrl
      }
      _rawSuccessMessage(resolveReferences: { maxDepth: 4 })
    }
  }
`;

interface GuideWorkshopPageProps {
  data: {
    sanityGuideWorkshop: {
      slug: {
        current: string;
      };
      topBannerText?: string;
      hero: HeroBigVideoProps;
      noteSection: NoteSectionProps;
      whyUsSection: WhyUsSectionProps;
      methodSection: MethodSectionProps;
      journeySection: JourneySectionProps;
      caseStudies: Array<CaseStudy>;
      testimonialsSection: TestimonialsSectionProps;
      ctaSection: {
        title: string;
        text: string;
        url: string;
        formType?: 'calendar' | 'typeform' | 'none';
      };
      seo: LocalizedSEOLandingPage;
    };
    allSanityClientBrand: {
      nodes: Array<{
        whiteLogo: SanityImageType;
        darkLogo: SanityImageType;
        title: string;
      }>;
    };
    sanityGetFileSettings: {
      downloadableFiles: Array<{
        fileParameterSlug: {
          current: string;
        };
        fileUrl: string;
      }>;
      _rawSuccessMessage: RawPortableText;
    };
  };
}

const GuideWorkshopPage = ({ data }: GuideWorkshopPageProps): React.ReactElement => {
  const {
    hero,
    topBannerText,
    noteSection,
    whyUsSection,
    methodSection,
    journeySection,
    caseStudies,
    testimonialsSection,
    ctaSection,
    seo,
  } = data.sanityGuideWorkshop;

  const isHidden = useActivateGoogleOptimize();

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const [ctaVersion, setCtaVersion] = useState<'none' | 'default'>('none');

  const { showNotification } = useNotifications();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const fileParamValue = urlSearchParams.get(FILE_QUERY_PARAM);
    const noAutoDownloadParamValue = urlSearchParams.get(NOAUTODOWNLOAD_QUERY_PARAM) !== null;
    const ctaParamValue = urlSearchParams.get(CTA_QUERY_PARAM);

    if (ctaParamValue !== 'none') {
      setCtaVersion('default');
    }

    if (fileParamValue || noAutoDownloadParamValue) {
      // Remove file and noautodownload query params
      removeParamsFromQueryParams([FILE_QUERY_PARAM, NOAUTODOWNLOAD_QUERY_PARAM]);

      if (fileParamValue) {
        const downloadableFile = data.sanityGetFileSettings.downloadableFiles.find(
          ({ fileParameterSlug }) => slugify(fileParameterSlug.current) === slugify(fileParamValue),
        );
        if (downloadableFile) {
          showNotification(
            <BlockContent
              renderContainerOnSingleChild
              blocks={data.sanityGetFileSettings._rawSuccessMessage}
              serializers={getSerializers(downloadableFile.fileUrl)}
            />,
          );
        }
      }
    }
  }, []);

  return (
    <div className={styles.container} style={isHidden ? { opacity: 0 } : undefined}>
      <Helmet>
        <html className={styles.html}></html>
      </Helmet>
      <PageSEO
        pageSEO={{
          title: seo.title,
          shareTitle: seo.shareTitle,
          description: seo.description,
          image: seo.image,
          noFollow: seo.noFollow,
          noIndex: seo.noIndex,
        }}
      />

      <motion.div className={styles.progressBar} style={{ scaleX }} />
      <header className={clsx(styles.header, styles.stickyHeader)}>
        <div className={styles.headerContainer}>
          <img className={styles.logoContainer} src={logoImageUrl} alt="" />
          <div className={styles.visuallyHidden}>{AUTHOR_NAME}</div>

          {ctaVersion === 'default' && (
            <SmartLink
              onClick={() => {
                withDataLayer(dataLayer => {
                  dataLayer.push({ event: 'cta-button-click', context: 'header' });
                });
              }}
              to={ctaSection.url}
            >
              <Button className={styles.ctaButton}>{'Schedule a call'}</Button>
            </SmartLink>
          )}
        </div>
      </header>
      {topBannerText && (
        <div className={clsx(styles.topBanner, topBannerText.length > 100 && styles.longText)}>
          {replaceNewLinesWithBr(topBannerText)}
        </div>
      )}
      <div role="main" className={styles.main}>
        <HeroBigVideo {...hero} trustedBrands={data.allSanityClientBrand.nodes} />
        <NoteSection {...noteSection} />
        <WhyUsSection {...whyUsSection} />
        <MethodSection {...methodSection} />
        <JourneySection {...journeySection} />
        {caseStudies && caseStudies.length > 0 && <CaseStudiesSection caseStudies={caseStudies} />}
        <TestimonialsSection {...testimonialsSection} />
        {ctaVersion === 'default' && <CtaSection {...ctaSection} />}
      </div>
    </div>
  );
};

export default GuideWorkshopPage;
